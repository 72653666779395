













































import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {defineComponent} from '@vue/composition-api';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';

export default defineComponent({
  name: 'UOttawaPostLab3DiscussionPart2',
  components: {AiLoadingOverlay, StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        part2a: null,
        part2b: null,
        part2c: null,
        part2d: null,
        part2e: null,
        part21: null,
        part2f: null,
        part2g: null,
        language: 'en',
      },
      questions: [
        {
          en: 'd) What do you observe? What ions are causing the colour you observe?',
          fr: 'd) Qu’observez-vous ? Quels ions produisent la couleur observée?',
          inputName: 'part2a',
        },
        {
          en: 'e) What changes do you observe? From the chemical reactions provided, what chemistry explains the observations you made? What ion(s) cause(s) this colour?',
          fr: 'e) Quels changements observez-vous ? Lesquelles parmi les réactions chimiques dans Tableau 1 peuvent expliquer les observations que vous avez faites? Quel(s) ion(s) produi(sen)t cette couleur?',
          inputName: 'part2b',
        },
        {
          en: 'f) What changes do you observe? Do you think this change is reversible? Why? From the chemical reactions provided, what chemistry explains the observations you made?',
          fr: 'f) Quels changements observez-vous ? Pensez-vous que le changement est réversible? Pourquoi? Lesquelles parmi les réactions chimiques dans Tableau 1 peuvent expliquer les observations que vous avez faites?',
          inputName: 'part2c',
        },
        {
          en: 'g) What changes do you observe? From the chemical reactions provided, what chemistry explains the observations you made?',
          fr: 'g) Quels changements observez-vous ? Lesquelles parmi les réactions chimiques dans Tableau 1 peuvent expliquer les observations que vous avez faites?',
          inputName: 'part2d',
        },
        {
          en: 'h) What changes do you observe? From the chemical reactions provided, what chemistry explains the observations you made? What ions are causing the colour you observe?',
          fr: 'h) Quels changements observez-vous ? Lesquelles parmi les réactions chimiques dans Tableau 1 peuvent expliquer les observations que vous avez faites? Quel ion produit cette couleur?',
          inputName: 'part2e',
        },
        {
          en: '2.) Can you observe the same changes if you repeat Steps 7 and 9?',
          fr: '2.) Pouvez-vous observer les mêmes changements si vous répétez les étapes 7 et 9 ? Pourquoi?',
          inputName: 'part21',
        },
        {
          en: 'i) What changes do you observe? From the chemical reactions provided, what chemistry explains the observations you made?',
          fr: 'i) Quels changements observez-vous ? Lesquelles parmi les réactions chimiques dans Tableau 1 peuvent expliquer les observations que vous avez faites?',
          inputName: 'part2f',
        },
        {
          en: 'j) What changes do you observe? From the chemical reactions provided, what chemistry explains the observations you made?',
          fr: 'j) Quels changements observez-vous ? Lesquelles parmi les réactions chimiques dans Tableau 1 peuvent expliquer les observations que vous avez faites?',
          inputName: 'part2g',
        },
      ],
    };
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
});
